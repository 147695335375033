/* AddItem.css */

.add-item-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em; /* Larger font size for the heading */
  color: #333; /* Darker color for better readability */
}

form {
  display: flex;
  flex-direction: column;
}

input[type="text"],
input[type="number"],
textarea {
  margin-bottom: 15px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #f7f7f7; /* Slightly different background color for inputs */
}

input[type="file"] {
  display: none; /* Hide the default file input */
}

.image-upload-label {
  display: inline-block;
  padding: 12px 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 15px;
  /* Add space for other elements and match input field styles */
}

.image-upload-label:hover {
  background-color: #0056b3;
}

button {
  padding: 12px;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #d9534f; /* Bootstrap's danger color for errors */
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold; /* Bold font for better visibility */
}

/* Style for Autocomplete input field */
input[placeholder="Enter location"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow to differentiate from other inputs */
  margin-bottom: 15px; /* Space between fields */
}

input[placeholder="Enter location"]::placeholder {
  color: #999; /* Light color for placeholder text */
}

/* Style for select category dropdown */
select {
  margin-bottom: 15px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #f7f7f7; /* Match with other inputs */
  appearance: none; /* Remove default styling */
  background: url('data:image/svg+xml;charset=UTF-8,%3Csvg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M7 10l5 5 5-5H7z" fill="%23999"/%3E%3C/svg%3E') no-repeat right 10px center;
  background-size: 12px;
}

select:focus {
  outline: none;
  border-color: #007bff; /* Highlight on focus */
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); /* Focus ring */
}

/* New Styles for Item Type Toggle */

.item-type-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.item-type-toggle label {
  margin-right: 15px;
  font-size: 16px;
  color: #333;
}

.item-type-toggle input[type="radio"] {
  margin-right: 8px;
}

/* Style for Radio Buttons */
input[type="radio"] {
  margin-right: 8px;
  cursor: pointer;
}

/* Adjusting form elements for better spacing */
form > *:not(.item-type-toggle) {
  margin-bottom: 15px;
}

/* Improved alignment for the image upload label and buttons */
.image-upload-label, button {
  display: block;
  width: 100%;
  text-align: center;
}
