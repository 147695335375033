/* src/components/Auth/Login.css */
.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login-title {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .login-error {
    color: #ff4d4d;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between input fields and button */
  }
  
  .login-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .login-button {
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  .login-register {
    text-align: center;
    margin-top: 20px;
  }
  
  .login-register-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .login-register-link:hover {
    text-decoration: underline;
  }
  