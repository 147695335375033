.edit-item-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
textarea,
select {
  margin-bottom: 15px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #f7f7f7;
  width: 100%;
}

input[type="file"] {
  display: none; /* Hide default file input */
}

.image-upload-label {
  display: inline-block;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
  text-transform: uppercase;
}

.image-upload-label:hover {
  background-color: #0056b3;
}

button {
  padding: 12px;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #d9534f;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
}

input[placeholder="Enter location"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

textarea {
  resize: vertical;
  height: 100px;
}
