/* Container for the profile chats */
.profile-chats-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added subtle shadow for depth */
}

/* Heading styles */
.profile-chats-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333; /* Darker color for better readability */
}

/* List styling for conversations */
.profile-chats-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0; /* Remove default margin to align with container edges */
}

.profile-chats-container li {
  padding: 15px; /* Increased padding for better spacing */
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  display: flex;
  align-items: center; /* Center items vertically */
}

.profile-chats-container li:hover {
  background-color: #f9f9f9; /* Light background on hover */
}

/* Styling for conversation links */
.conversation-link {
  display: flex;
  justify-content: space-between; /* Aligns content to the edges */
  width: 100%;
  text-decoration: none;
  color: #333;
}

/* Container for item info */
.item-info {
  display: flex;
  align-items: center;
  width: calc(100% - 120px); /* Adjust for timestamp space */
}

/* Styling for item image */
.item-image {
  width: 50px;
  height: 50px;
  border-radius: 50%; /* Make image round */
  object-fit: cover; /* Ensure image covers the circle */
  margin-right: 15px; /* Space between image and text */
}

/* Container for item details */
.item-details {
  display: flex;
  flex-direction: column;
}

/* Styling for item title */
.item-title {
  font-weight: bold;
}

/* Styling for username */
.user-name {
  color: #666;
}

/* Timestamp styling */
.timestamp {
  font-size: 0.85em;
  color: #666;
}

.back-to-profile-link {
  display: inline-block;
  margin-bottom: 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.back-to-profile-link:hover {
  text-decoration: underline;
}

/* Additional styling for error messages */
.profile-chats-container .error-message {
  color: #ff4d4d;
  font-size: 1em;
  margin-top: 10px;
  text-align: center; /* Center the error message for better presentation */
}
