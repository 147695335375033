.privacy-policy {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin: 20px auto;
    max-width: 800px;
  }
  
  .privacy-policy h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .privacy-policy h2 {
    font-size: 1.5em;
    margin-top: 20px;
    color: #0056b3;
  }
  
  .privacy-policy p,
  .privacy-policy ul {
    margin-bottom: 20px;
  }
  
  .privacy-policy ul {
    padding-left: 20px;
  }
  
  .privacy-policyns ul li {
    margin-bottom: 10px;
  }
  