.footer {
    background-color: #343a40;
    color: #ffffff;
    padding: 20px 0;
    text-align: center;
    position: relative;
    width: 100%;
    bottom: 0;
  }
  
  .footer p {
    margin: 0;
    font-size: 1em;
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .footer-links a {
    color: #007bff;
    margin: 0 15px;
    text-decoration: none;
    font-size: 0.9em;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
    color: #0056b3;
  }
  