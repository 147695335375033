.review-form {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-form h3 {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.review-form label {
  display: block;
  margin-bottom: 15px;
  font-size: 1.1em;
  color: #333;
}

.review-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  height: 100px;
  resize: none;
}

.star-rating {
  display: flex;
}

.star {
  font-size: 1.5em; /* Reduced star size */
  cursor: pointer;
  color: #ddd; /* Default color for empty stars */
  transition: color 0.2s;
}

.star.filled {
  color: #ffc107; /* Color for filled stars */
}

.review-form button {
  width: 100%;
  padding: 10px;
  font-size: 1.1em;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.review-form button:hover {
  background-color: #0056b3;
}
.popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.popup-content h3 {
  margin-bottom: 20px;
}

.popup-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-bottom: 15px;
  text-align: center;
}
