/* src/components/Navbar.css */

.navbar {
  background-color: #343a40;
  padding: 10px 20px;
  color: white;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo {
  font-size: 2em;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  font-style: italic; /* Italicize the logo */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Add a subtle text shadow */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition on hover */
}

.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}

.navbar-menu li {
  margin-left: 20px;
}

.navbar-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.navbar-link:hover {
  color: #007bff;
}

.profile-icon {
  width: 28px;
  height: 28px;
  vertical-align: middle;
  filter: invert(1);
}

.navbar-profile-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.language-switcher {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.language-icon {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1em;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.language-icon:hover {
  background-color: #007bff;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 100px;
}

.dropdown-item {
  background-color: #ffffff;
  color: #333;
  border: none;
  padding: 8px 16px;
  text-align: left;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #ddd;
  font-size: 1em;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
  color: #007bff;
}

.messages-link {
  position: relative;
}

.message-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: orange;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
}

/* Mobile Styles */
.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 2em;
  color: white;
  cursor: pointer;
  margin-left: auto; /* Push the button to the far right */
}

@media (max-width: 768px) {
  .navbar-container {
    flex-direction: row;
  }

  .navbar-menu {
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #343a40;
    width: 100%;
    display: none; /* Hide the menu by default */
  }

  .navbar-menu.open {
    display: flex; /* Show the menu when open */
  }

  .navbar-menu li {
    margin: 10px 0;
    text-align: center;
  }

  .mobile-menu-toggle {
    display: block;
  }
}
