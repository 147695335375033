/* src/components/ConversationDetail/ConversationDetail.css */
.conversation-detail-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.messages {
  margin-bottom: 20px;
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
}

.message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  max-width: 70%;
  display: block; /* Ensures each message is in a new row */
  clear: both; /* Clears floating elements if any */
}

.message.sent {
  background-color: #e0f7fa;
  text-align: right;
  margin-left: auto;
}

.message.received {
  background-color: #f1f8e9;
  text-align: left;
}

.message-form textarea {
  width: calc(100% - 22px); /* Adjust for padding and border */
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.message-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.message-form button:hover {
  background-color: #0056b3;
}

.rental-request-form {
  margin-top: 20px;
}

.rental-request-form h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333; /* Consistent with the rest of the text */
}

.rental-request-form .rental-date-row-inline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.rental-request-form .rental-date-col {
  flex: 1;
  margin-right: 10px; /* Adds space between the columns */
}

.rental-request-form .rental-date-col:last-child {
  margin-right: 0; /* Removes margin for the last column */
}

.rental-request-form label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.rental-request-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.rental-request-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px; /* Add spacing above button */
}

.rental-request-form button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
}

.back-link {
  display: inline-block;
  margin-bottom: 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.back-link:hover {
  text-decoration: underline;
}
