.homepage {
  font-family: Arial, sans-serif;
  color: #333;
  text-align: center;
}

.hero-section {
  background: #f0f8ff;
  padding: 50px 20px;
  margin-bottom: 20px;
}

.hero-section h1 {
  font-size: 2.5em;
  margin: 0;
}

.hero-section p {
  font-size: 1.2em;
  margin: 20px 0;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.1em;
  color: #fff;
  background: #343a40;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.cta-button:hover {
  background: #0056b3;
}

.login-button {
  background-color: #343a40;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Items Nearby Section */
.items-nearby {
  padding: 20px;
  background-color: #f9f9f9;
  position: relative;
}

.items-list-container {
  position: relative;
  display: flex;
  align-items: center;
}

.items-list {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 10px;
  scroll-behavior: smooth;
  width: 100%;
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

.items-list::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
}

/* Adjusted height for item-card */
.item-card {
  width: 200px; /* Set a fixed width */
  height: 300px; /* Set a fixed height for the item card */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures that content is spaced evenly */
}

/* Increase the height of images */
.item-card img {
  width: 100%;
  height: 180px; /* Increase the height of the image */
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

.item-card a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.item-card a:hover {
  color: black;
  text-decoration: none;
}

/* Adjust text within item-card */
.item-card h3 {
  font-size: 1.2em;
  margin: 10px 0;
}

.item-card p {
  font-size: 1em;
  margin: 5px 0;
  flex-grow: 1; /* Allows description to grow if needed */
}

.scroll-arrow {
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-arrow {
  left: 0;
  margin-left: 10px;
}

.right-arrow {
  right: 0;
  margin-right: 10px;
}

.scroll-arrow:hover {
  background-color: #0056b3;
}

/* Hide arrows on smaller screens (below 768px) */
@media (max-width: 768px) {
  .scroll-arrow {
    display: none;
  }
}

/* Features Section */
.features {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background: #e9ecef;
  flex-wrap: wrap;
}

.feature {
  flex: 1;
  padding: 5px;
  max-width: 300px;
  text-align: left;
  margin: 10px;
}

.feature h2 {
  font-size: 1.5em;
}

@media (max-width: 768px) {
  .features {
    flex-direction: column;
    align-items: center;
  }

  .feature {
    max-width: none;
    width: 100%;
  }
}
