/* ItemList.css */

.item-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.item-type-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* Space between toggle and search bar */
}

.toggle-button {
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.toggle-button.active {
  background-color: #007bff;
  color: white;
}

.filters {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between filters */
}

.search-bar,
.category-filter,
.location-filter,
.radius-filter {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f7f7f7;
}

.radius-filter {
  max-width: 200px;
}

.item-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.item-list-item {
  flex: 1 1 calc(33.333% - 20px);
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.item-list-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.item-list-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 0;
}

.item-list-item-content {
  padding: 15px;
}

.item-list-item-title {
  font-size: 1.2em;
  margin: 0;
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: bold; /* Add this to make it bold */
}

.item-list-rating {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.rating-number {
  font-size: 1rem !important; /* Force the size of the numeric rating */
  margin-right: 8px;
  color: #333;
}

.star-rating-inline {
  display: flex;
}

.star {
  font-size: 1rem !important; /* Ensure the size of the stars is smaller */
  color: #ddd;
  margin-right: 3px;
  position: relative;
}

.star.filled {
  color: #ffc107;
}

.star.half-filled::before {
  content: "\2605";
  position: absolute;
  left: 0;
  color: #ffc107;
  width: 50%;
  overflow: hidden;
  z-index: 1;
}

.star.half-filled::after {
  content: "\2605";
  color: #ddd;
  position: absolute;
  left: 0;
  z-index: 0;
}

.item-list-item-description {
  font-size: 0.9em;
  color: #666;
}

.item-list-item-price {
  font-size: 1.1em;
  color: #000;
}

.item-list-item-location {
  font-size: 0.8em;
  color: #888;
  margin-top: 10px;
}
