.item-detail-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.item-detail-container h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.item-detail-container img {
  width: 100%;
  max-width: 1000px;
  height: auto;
  border-radius: 8px;
  display: block;
  margin: 0 auto;
}

.average-rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.rating-number {
  font-size: 1.2rem;
  margin-right: 10px;
}

.star-rating-inline {
  display: flex;
  align-items: center;
}

.star {
  font-size: 1.2rem;
  color: #ddd;
  margin-right: 5px;
  position: relative;
}

.filled {
  color: #ffc107 !important;
}

.half-filled::before {
  content: "\2605";
  position: absolute;
  left: 0;
  color: #ffc107 !important;
  width: 50%;
  overflow: hidden;
  z-index: 1;
}

.half-filled::after {
  content: "\2605";
  color: #ddd !important;
  position: absolute;
  left: 0;
  z-index: 0;
}

.empty {
  color: #ddd;
}

.item-detail-container p {
  font-size: 1rem;
  margin: 10px 0;
}

.message-form-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.message-form {
  margin: 0;
}

.message-form h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.message-form textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px; /* Rounded corners for textarea */
  margin-bottom: 10px;
  font-size: 1rem; /* Consistent font size */
}

.message-form h3 {
  font-size: 1.2rem;
  margin: 10px 0;
}

.rental-date-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.rental-date-row {
  flex: 1 1 45%;
  display: flex;
  align-items: center;
}

.rental-date-row label {
  margin-right: 10px;
  white-space: nowrap;
  font-size: 1rem; /* Consistent font size */
}

.rental-date-row input[type="date"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 12px; /* More rounded corners for date input */
  font-size: 1rem;
  background-color: #f5f5f5; /* Light background color for input */
}

.rental-date-row input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(50%); /* Adjusts color of the calendar icon */
}

.message-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px; /* Rounded corners for button */
  cursor: pointer;
  margin-top: 10px;
}

.message-form button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 1rem;
}

.reviews-section {
  margin-top: 40px;
}

.reviews-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.review {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.review p {
  margin: 5px 0;
}

.review hr {
  margin-top: 10px;
  border: 0;
  height: 1px;
  background-color: #ddd;
}
