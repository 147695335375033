.profile-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.profile-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.profile-chats-link,
.add-item-link {
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 20px;
  text-decoration: none;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 8px;
  font-size: 1em;
  background-color: #fff;
}

.profile-chats-link:hover,
.add-item-link:hover {
  background-color: #007bff;
  color: #fff;
}

.logout-link {
  background: none !important;
  border: none !important;
  color: #007bff;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 20px;
  margin-top: 20px;
  display: inline-block;
}

.logout-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.edit-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background: none !important;
  border: none !important;
  color: #d9534f;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;
}

.delete-button:hover {
  text-decoration: underline;
}

.item-list {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.item {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
}

.item h3 {
  margin: 10px;
  font-size: 1.2em;
}

.item p {
  margin: 10px;
}

/* Rentals section styling */
.rentals-section {
  margin-top: 30px;
}

.rentals-section h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.rental-cluster {
  margin-bottom: 20px;
}

.rental-list {
  list-style: none;
  padding: 0;
}

.rental-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  margin-bottom: 10px;
}

.rental-item img {
  width: 60px; /* Adjust size of item images */
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 15px;
}

.rental-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.rental-info {
  display: flex;
  flex-grow: 1;
  align-items: center; /* Ensure items are vertically centered */
}

/* Align dates, icons, and names inline */
.rental-item-inline {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px; /* Ensure there's spacing between details and buttons */
}

.rental-item-date {
  font-size: 0.9em;
  color: #555;
  margin-right: 10px;
}

.rental-icon {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}

.rental-icon-img {
  width: 24px;
  height: 24px;
}

.rental-user {
  margin-left: 10px;
}

/* Additional Styling for Rental Actions */
.rental-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px; /* Adds space between details and buttons */
  flex-direction: column; /* Stack the buttons vertically */
}

.confirm-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-button:hover {
  background-color: #218838;
}

.reject-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 15px;
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reject-button:hover {
  background-color: #c82333;
}

.leave-review-button {
  display: inline-block;
  background-color: #ffc107;
  color: white;
  padding: 8px 15px;
  font-size: 0.9em;
  text-align: center;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 10px;
}

.leave-review-button:hover {
  background-color: #e0a800;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
}

.leave-review-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); /* Outline for accessibility */
}

.error-message {
  color: red;
  font-weight: bold;
}
